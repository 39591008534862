import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, Outlet, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../constants/appRoutes";
import { useLogout } from "../hooks/useLogout";
import { useCurrentUserContext } from "../core/providers/currentUser";
import { useColorModeContext } from "../core/providers/colorMode";
import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

export const Layout: React.FC = () => {
  const theme = useTheme();
  const colorMode = useColorModeContext();
  const { user, loading: userLoading } = useCurrentUserContext();
  const { loading: logoutLoading, logout } = useLogout();
  const loading = React.useMemo(
    () => userLoading || logoutLoading,
    [userLoading, logoutLoading]
  );
  const routes: { route: string; title: string }[] = React.useMemo(() => {
    const r: any[] = [
      { route: APP_ROUTES.DOMAINS, title: "DOMAINS" },
      { route: APP_ROUTES.PRESETS, title: "PRESETS" },
      { route: APP_ROUTES.MAIL_CHECKER, title: "MAIL CHECKER" },
    ];

    const { role } = user;
    if (role === "ADMIN") {
      r.unshift({ route: APP_ROUTES.USERS, title: "USERS" });
    }
    return r;
  }, [user]);
  const location = useLocation();
  const currentRoute = React.useMemo(() => location.pathname, [location]);

  return (
    <Container>
      <UserPanel>
        <Box>
          <IconButton
            sx={{ ml: 1, color: "white" }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
          <div className="user">
            {!loading && (
              <>
                <AccountCircleOutlinedIcon />
                <Typography variant="body1">{user?.username}</Typography>
              </>
            )}
            {loading && <CircularProgress size="2rem" color="inherit" />}
          </div>
          <Button variant="outlined" onClick={logout}>
            logout
          </Button>
        </Box>
      </UserPanel>

      <NavPanel>
        {routes &&
          routes.map(({ route, title }, i) => (
            <Link
              key={i}
              to={route}
              className={`nav-link ${currentRoute === route ? "selected" : ""}`}
            >
              {title}
            </Link>
          ))}
      </NavPanel>

      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

const Container = styled("div")(() => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  maxHeight: "100vh",
  overflow: "hidden",
}));

const UserPanel = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 50px;
    background: ${theme.palette.primary.main};

    & .MuiBox-root {
      display: flex;
      align-items: center;
      gap: 20px;

      & div {
        color: white;
        display: flex;
      }

      & .MuiButton-root {
        color: white;
        border-color: white;
        margin-right: 25px;
      }
    }
  }
`
);

const NavPanel = styled(Box)(
  ({ theme }) => `
  &.MuiBox-root {
    background: ${theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 48px;

    & .nav-link {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: 48px;
      width: 160px;
      &.selected {
        border-bottom: 2px solid #E0E0E0;
      }
    }
  }
`
);

const Content = styled("div")(() => ({
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minWidth: "100%",
  height: "calc(100vh - 48px - 80px)",
  minHeight: "calc(100vh - 48px - 80px)",
  maxHeight: "calc(100vh - 48px - 80px)",
  overflowY: "hidden",
  overflowX: "auto",
}));
