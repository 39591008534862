import React from "react";
import { API_ROUTES } from "../constants/apiRoutes";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../constants/appRoutes";
import { useAxiosContext } from "../core/providers/axios";
import { useLocalStorage } from "./useLocalStorage";

interface ReturnValue {
  loading: boolean;
  logout: () => void;
}

export const useLogout = (): ReturnValue => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [, , , clearLocalStorage] = useLocalStorage();
  const axios = useAxiosContext();

  const logout = React.useCallback(() => {
    setLoading(true);
    axios &&
      axios
        .post(API_ROUTES.LOGOUT, null)
        // ERROR HANDLING PROVIDED IN AXIOS PROVIDER
        .catch(() => {})
        // CLEAR LOCAL STORAGE AND NAVIGATE USER TO LOGIN PAGE ANYWAY
        .finally(() => {
          clearLocalStorage();
          navigate(APP_ROUTES.LOGIN);
          setLoading(false);
        });
  }, [axios, clearLocalStorage, navigate]);

  return { logout, loading };
};
