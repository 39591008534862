import React from "react";
import { CurrentUserContext } from "./CurrentUserContext";

export const useCurrentUserContext = () => {
  const value = React.useContext(CurrentUserContext);
  if (!value) {
    throw new Error("useCurrentUserContext used outside of CurrentUserContext");
  }
  return value;
};
