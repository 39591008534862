import React from "react";

export const useLocalStorage = (): [
  <T = any>(_key: string) => T | null,
  (_key: string, _value: any) => void,
  () => void,
  () => void
] => {
  const getItem = React.useCallback(<T = any>(key: string): T | null => {
    try {
      const saved = localStorage.getItem(key);
      const value = JSON.parse(saved || "");
      return value;
    } catch (err) {
      console.log({ err });
      return null;
    }
  }, []);

  const setItem = React.useCallback(
    (key: string, value: any): void =>
      localStorage.setItem(key, JSON.stringify(value)),
    []
  );

  const clearAll = React.useCallback(() => localStorage.clear(), []);

  const logoutClear = React.useCallback(() => {
    const disableInstallButton = getItem("disableInstallButton");
    const mode = getItem("colorMode");
    localStorage.clear();
    setItem("colorMode", mode);
    setItem("disableInstallButton", disableInstallButton || false);
  }, []);

  return [getItem, setItem, clearAll, logoutClear];
};
